<template>
  <div class="title">
    <span><i aria-hidden="true" class="fa fa-tags"></i> <slot></slot></span>
  </div>
</template>

<script>
  export default {
    name: "SubTitle"
  }
</script>

<style scoped>

</style>