import http from "./axios/http";


export function sellList(search, pageNum, pageSize, orderBy) {
  return http.post('/market/list', search)
}
export function sell(search, pageNum, pageSize, orderBy) {
  return http.post('/market/sell', search)
}
export function cancelSell(id) {
  return http.get('/market/cancel_sell?id=' + id)
}
export function buy(id) {
  return http.get('/market/buy?id=' + id)
}

/*판매리스트*/
export function sellBuyHistory(search, pageNum, pageSize) {
  return http.post('/market/sell_buy_history?pageNum=' + pageNum + '&pageSize=' + pageSize, search)
}
/*판매리스트*/
export function marketItems(market, pageNum, pageSize) {
  return http.post('/market/list?pageNum=' + pageNum + '&pageSize=' + pageSize, market)
}
