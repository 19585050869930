<template>

    <div class="sports-links">
        <router-link tag="a" to="/sell_list" class="link" active-class="active_sports_link">거래소</router-link>
        <router-link tag="span" to="" class="link fg">|</router-link>
        <router-link tag="a" to="/sell_sportsbetinfo" class="link" active-class="active_sports_link">판매등록</router-link>
        <router-link tag="span" to="" class="link fg">|</router-link>
        <router-link tag="a" to="/sell_history" class="link" active-class="active_sports_link">판매내역</router-link>
        <router-link tag="span" to="" class="link fg">|</router-link>
        <router-link tag="a" to="/buy_history" class="link" active-class="active_sports_link">구매내역</router-link>
    </div>
</template>

<script>
    import sportsConst from "../../common/sportsConst";
    import {RECEIVE_DISPLAY_POSTION} from "../../store/mutation-types";

    export default {
        name: "MarketLinksComp",
        props: {
        },
        data() {
            return {
                sportsConst,
            }
        },
        methods: {

        },
    }
</script>

<style scoped>

</style>