<template>

    <div class="sports-links">
        <router-link tag="a" :to="{path: '/sports', query: {s: new Date().getTime()}}" class="link" active-class="active_sports_link">스포츠</router-link>
        <router-link tag="span" to="" class="link fg">|</router-link>
        <router-link tag="a" :to="{path: '/sports_inplay', query: {s: new Date().getTime()}}" class="link" active-class="active_sports_link">라이브</router-link>
        <router-link tag="span" to="" class="link fg">|</router-link>
        <router-link tag="a" :to="{path: '/sports_result', query: {s: new Date().getTime()}}" class="link" active-class="active_sports_link">게임결과</router-link>
        <router-link tag="span" to="" class="link fg">|</router-link>
        <router-link tag="a" :to="{path: '/sports_betinfo', query: {s: new Date().getTime()}}" class="link" active-class="active_sports_link">참여내역</router-link>
    </div>
</template>

<script>
    import sportsConst from "../../common/sportsConst";
    import {RECEIVE_DISPLAY_POSTION} from "../../store/mutation-types";

    export default {
        name: "SportsLinksComp",
        props: {
        },
        data() {
            return {
                sportsConst,
            }
        },
        methods: {

        },
    }
</script>

<style scoped>

</style>